import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../base/i18n/functions';
import { getParticipantDisplayName } from '../../../base/participants/functions';
import Popover from '../../../base/popover/components/Popover.web';
import Message from '../../../base/react/components/web/Message';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import { getFormattedTimestamp, getMessageText, getPrivateNoticeMessage } from '../../functions';
import MessageMenu from './MessageMenu';
import ReactButton from './ReactButton';
const useStyles = makeStyles()((theme) => {
    return {
        chatMessageFooter: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(1)
        },
        chatMessageFooterLeft: {
            display: 'flex',
            flexGrow: 1,
            overflow: 'hidden'
        },
        chatMessageWrapper: {
            maxWidth: '100%'
        },
        chatMessage: {
            display: 'inline-flex',
            padding: '12px',
            backgroundColor: theme.palette.ui02,
            borderRadius: '4px 12px 12px 12px',
            maxWidth: '100%',
            marginTop: '4px',
            boxSizing: 'border-box',
            '&.privatemessage': {
                backgroundColor: theme.palette.support05
            },
            '&.local': {
                backgroundColor: theme.palette.ui04,
                borderRadius: '12px 4px 12px 12px',
                '&.privatemessage': {
                    backgroundColor: theme.palette.support05
                },
                '&.local': {
                    backgroundColor: theme.palette.ui04,
                    borderRadius: '12px 4px 12px 12px',
                    '&.privatemessage': {
                        backgroundColor: theme.palette.support05
                    }
                },
                '&.error': {
                    backgroundColor: theme.palette.actionDanger,
                    borderRadius: 0,
                    fontWeight: 100
                },
                '&.lobbymessage': {
                    backgroundColor: theme.palette.support05
                }
            },
            '&.error': {
                backgroundColor: theme.palette.actionDanger,
                borderRadius: 0,
                fontWeight: 100
            },
            '&.lobbymessage': {
                backgroundColor: theme.palette.support05
            }
        },
        sideBySideContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            marginLeft: theme.spacing(1)
        },
        reactionBox: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            backgroundColor: theme.palette.grey[800],
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0, 1),
            cursor: 'pointer'
        },
        reactionCount: {
            fontSize: '0.8rem',
            color: theme.palette.grey[400]
        },
        replyButton: {
            padding: '2px'
        },
        replyWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: '100%'
        },
        messageContent: {
            maxWidth: '100%',
            overflow: 'hidden',
            flex: 1
        },
        optionsButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(1),
            minWidth: '32px',
            minHeight: '32px'
        },
        displayName: {
            ...withPixelLineHeight(theme.typography.labelBold),
            color: theme.palette.text02,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            marginBottom: theme.spacing(1),
            maxWidth: '130px'
        },
        userMessage: {
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            color: theme.palette.text01,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
        },
        privateMessageNotice: {
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text02,
            marginTop: theme.spacing(1)
        },
        timestamp: {
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text03,
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            whiteSpace: 'nowrap',
            flexShrink: 0
        },
        reactionsPopover: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.ui03,
            borderRadius: theme.shape.borderRadius,
            maxWidth: '150px',
            maxHeight: '400px',
            overflowY: 'auto',
            color: theme.palette.text01
        },
        reactionItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
            gap: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.common.white}`,
            paddingBottom: theme.spacing(1),
            '&:last-child': {
                borderBottom: 'none',
                paddingBottom: 0
            }
        },
        participantList: {
            marginLeft: theme.spacing(1),
            fontSize: '0.8rem',
            maxWidth: '120px'
        },
        participant: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    };
});
const ChatMessage = ({ message, state, showDisplayName, type, shouldDisplayChatMessageMenu, knocking, t }) => {
    const { classes, cx } = useStyles();
    const [isHovered, setIsHovered] = useState(false);
    const [isReactionsOpen, setIsReactionsOpen] = useState(false);
    const handleMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);
    const handleMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);
    const handleReactionsOpen = useCallback(() => {
        setIsReactionsOpen(true);
    }, []);
    const handleReactionsClose = useCallback(() => {
        setIsReactionsOpen(false);
    }, []);
    /**
     * Renders the display name of the sender.
     *
     * @returns {React$Element<*>}
     */
    function _renderDisplayName() {
        return (React.createElement("div", { "aria-hidden": true, className: cx('display-name', classes.displayName) }, message.displayName));
    }
    /**
     * Renders the message privacy notice.
     *
     * @returns {React$Element<*>}
     */
    function _renderPrivateNotice() {
        return (React.createElement("div", { className: classes.privateMessageNotice }, getPrivateNoticeMessage(message)));
    }
    /**
     * Renders the time at which the message was sent.
     *
     * @returns {React$Element<*>}
     */
    function _renderTimestamp() {
        return (React.createElement("div", { className: cx('timestamp', classes.timestamp) }, getFormattedTimestamp(message)));
    }
    /**
     * Renders the reactions for the message.
     *
     * @returns {React$Element<*>}
     */
    const renderReactions = useMemo(() => {
        if (!message.reactions || message.reactions.size === 0) {
            return null;
        }
        const reactionsArray = Array.from(message.reactions.entries())
            .map(([reaction, participants]) => {
            return { reaction,
                participants };
        })
            .sort((a, b) => b.participants.size - a.participants.size);
        const totalReactions = reactionsArray.reduce((sum, { participants }) => sum + participants.size, 0);
        const numReactionsDisplayed = 3;
        const reactionsContent = (React.createElement("div", { className: classes.reactionsPopover }, reactionsArray.map(({ reaction, participants }) => (React.createElement("div", { className: classes.reactionItem, key: reaction },
            React.createElement("span", null, reaction),
            React.createElement("span", null, participants.size),
            React.createElement("div", { className: classes.participantList }, Array.from(participants).map(participantId => (React.createElement("div", { className: classes.participant, key: participantId }, state && getParticipantDisplayName(state, participantId))))))))));
        return (React.createElement(Popover, { content: reactionsContent, onPopoverClose: handleReactionsClose, onPopoverOpen: handleReactionsOpen, position: 'top', trigger: 'hover', visible: isReactionsOpen },
            React.createElement("div", { className: classes.reactionBox },
                reactionsArray.slice(0, numReactionsDisplayed).map(({ reaction }, index) => React.createElement("span", { key: index }, reaction)),
                reactionsArray.length > numReactionsDisplayed && (React.createElement("span", { className: classes.reactionCount },
                    "+",
                    totalReactions - numReactionsDisplayed)))));
    }, [message?.reactions, isHovered, isReactionsOpen]);
    return (React.createElement("div", { className: cx(classes.chatMessageWrapper, type), id: message.messageId, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, tabIndex: -1 },
        React.createElement("div", { className: classes.sideBySideContainer },
            !shouldDisplayChatMessageMenu && (React.createElement("div", { className: classes.optionsButtonContainer }, isHovered && React.createElement(MessageMenu, { isLobbyMessage: message.lobbyChat, message: message.message, participantId: message.participantId, shouldDisplayChatMessageMenu: shouldDisplayChatMessageMenu }))),
            React.createElement("div", { className: cx('chatmessage', classes.chatMessage, type, message.privateMessage && 'privatemessage', message.lobbyChat && !knocking && 'lobbymessage') },
                React.createElement("div", { className: classes.replyWrapper },
                    React.createElement("div", { className: cx('messagecontent', classes.messageContent) },
                        showDisplayName && _renderDisplayName(),
                        React.createElement("div", { className: cx('usermessage', classes.userMessage) },
                            React.createElement("span", { className: 'sr-only' }, message.displayName === message.recipient
                                ? t('chat.messageAccessibleTitleMe')
                                : t('chat.messageAccessibleTitle', {
                                    user: message.displayName
                                })),
                            React.createElement(Message, { text: getMessageText(message) }),
                            (message.privateMessage || (message.lobbyChat && !knocking))
                                && _renderPrivateNotice(),
                            React.createElement("div", { className: classes.chatMessageFooter },
                                React.createElement("div", { className: classes.chatMessageFooterLeft }, message.reactions && message.reactions.size > 0 && (React.createElement(React.Fragment, null, renderReactions))),
                                _renderTimestamp()))))),
            shouldDisplayChatMessageMenu && (React.createElement("div", { className: classes.sideBySideContainer },
                !message.privateMessage && React.createElement("div", null,
                    React.createElement("div", { className: classes.optionsButtonContainer }, isHovered && React.createElement(ReactButton, { messageId: message.messageId, receiverId: '' }))),
                React.createElement("div", null,
                    React.createElement("div", { className: classes.optionsButtonContainer }, isHovered && React.createElement(MessageMenu, { isLobbyMessage: message.lobbyChat, message: message.message, participantId: message.participantId, shouldDisplayChatMessageMenu: shouldDisplayChatMessageMenu }))))))));
};
/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
function _mapStateToProps(state, { message }) {
    const { knocking } = state['features/lobby'];
    const localParticipantId = state['features/base/participants'].local?.id;
    return {
        shouldDisplayChatMessageMenu: message.participantId !== localParticipantId,
        knocking,
        state
    };
}
export default translate(connect(_mapStateToProps)(ChatMessage));
