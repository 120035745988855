import { NoiseSuppressionEffect } from '../../stream-effects/noise-suppression/NoiseSuppressionEffect';
import { createVirtualBackgroundEffect } from '../../stream-effects/virtual-background';
import logger from './logger';
/**
 * Loads the enabled stream effects.
 *
 * @param {Object} store - The Redux store.
 * @returns {Promise} - A Promise which resolves when all effects are created.
 */
export default function loadEffects(store) {
    const start = window.performance.now();
    const state = store.getState();
    const virtualBackground = state['features/virtual-background'];
    const noiseSuppression = state['features/noise-suppression'];
    const { noiseSuppression: nsOptions } = state['features/base/config'];
    const backgroundPromise = virtualBackground.backgroundEffectEnabled
        ? createVirtualBackgroundEffect(virtualBackground)
            .catch((error) => {
            logger.error('Failed to obtain the background effect instance with error: ', error);
            return Promise.resolve();
        })
        : Promise.resolve();
    const noiseSuppressionPromise = noiseSuppression?.enabled
        ? Promise.resolve(new NoiseSuppressionEffect(nsOptions))
        : Promise.resolve();
    return Promise.all([backgroundPromise, noiseSuppressionPromise]).then(effectsArray => {
        const end = window.performance.now();
        logger.debug(`(TIME) loadEffects() start=${start}, end=${end}, time=${end - start}`);
        return effectsArray;
    });
}
